import React from "react"
import PropTypes from "prop-types"
import FavoriteIcon from '@material-ui/icons/Favorite';

import '../style/style.min.css'
import '../style/bootstrap-grid.min.css'
import '../style/custom.style.css'

const Layout = ({ children }) => {

  return (
      <main>
        {children}
        <div class="footer">
          <div className="zorrilla mt-5 text-right text-secondary footer">
            <small><a href="https://www.zorrillamedia.de/impressum/">Impressum</a> &#183; Made &amp; Managed with <FavoriteIcon style={{color: "#F17834"}}/> by <a href="https://www.zorrillamedia.de" style={{color: "#F17834"}}>Zorrilla Media</a></small>
          </div>  
        </div>
      </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
